(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var Animate = function () {
    'use strict';

    var Animate = function Animate(userOptions) {
        var el = document.createElement('fakeelement');
        var defaultOptions = {
            target: '[data-animate]',
            animatedClass: 'js-animated',
            offset: [0.5, 0.5],
            delay: 0,
            remove: true,
            scrolled: false,
            reverse: false,
            onLoad: true,
            onScroll: true,
            onResize: false,
            disableFilter: null,
            callbackOnInit: function callbackOnInit() {},
            callbackOnInView: function callbackOnInView() {},
            callbackOnAnimate: function callbackOnAnimate() {}
        };

        this.supports = 'querySelector' in document && 'addEventListener' in window && 'classList' in el && Function.prototype.bind;
        this.options = this._extend(defaultOptions, userOptions || {});
        this.elements = document.querySelectorAll(this.options.target);
        this.initialised = false;

        this.verticalOffset = this.options.offset;
        this.horizontalOffset = this.options.offset;

        // Offset can be [y, x] or the same value can be used for both
        if (this._isType('Array', this.options.offset)) {
            this.verticalOffset = this.options.offset[0];
            this.horizontalOffset = this.options.offset[1] ? this.options.offset[1] : this.options.offset[0];
        }

        this.throttledEvent = this._debounce(function () {
            this.render();
        }.bind(this), 15);
    };

    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    // @private
    // @author David Walsh
    // @link https://davidwalsh.name/javascript-debounce-function
    Animate.prototype._debounce = function (func, wait, immediate) {
        var timeout;
        return function () {
            var context = this;
            var args = arguments;
            var later = function later() {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
                func.apply(context, args);
            }
        };
    };

    /**
     * Merges unspecified amount of objects into new object
     * @private
     * @return {Object} Merged object of arguments
     */
    Animate.prototype._extend = function () {
        var extended = {};
        var length = arguments.length;

        /**
         * Merge one object into another
         * @param  {Object} obj  Object to merge into extended object
         */
        var merge = function merge(obj) {
            for (var prop in obj) {
                if (Object.hasOwnProperty.call(obj, prop)) {
                    extended[prop] = obj[prop];
                }
            }
        };

        // Loop through each passed argument
        for (var i = 0; i < length; i++) {
            // Store argument at position i
            var obj = arguments[i];

            // If we are in fact dealing with an object, merge it. Otherwise throw error
            if (this._isType('Object', obj)) {
                merge(obj);
            } else {
                console.error('Custom options must be an object');
            }
        }

        return extended;
    };

    /**
     * Determines when an animation has completed
     * @author  David Walsh
     * @link https://davidwalsh.name/css-animation-callback
     * @private
     * @return {String} Appropriate 'animationEnd' event for browser to handle
     */
    Animate.prototype._whichAnimationEvent = function () {
        var t;
        var el = document.createElement('fakeelement');

        var animations = {
            animation: 'animationend',
            OAnimation: 'oAnimationEnd',
            MozAnimation: 'animationend',
            WebkitAnimation: 'webkitAnimationEnd'
        };

        for (t in animations) {
            if (Object.hasOwnProperty.call(animations, t)) {
                if (el.style[t] !== undefined) {
                    return animations[t];
                }
            }
        }
    };

    /**
     * Determines whether we have already scrolled past the element
     * @param  {HTMLElement}  el Element to test
     * @return {Boolean}
     */
    Animate.prototype._isAboveScrollPos = function (el) {
        var dimensions = el.getBoundingClientRect();
        var scrollPos = window.scrollY || window.pageYOffset;

        return dimensions.top + dimensions.height * this.verticalOffset < scrollPos;
    };

    /**
     * Determines the offset for a particular element considering
     * any attribute overrides. Falls back to config options otherwise
     * @param  {HTMLElement} el Element to get offset for
     * @return {Array}    An offset array of [Y,X] offsets
     */
    Animate.prototype._getElementOffset = function (el) {
        var elementOffset = el.getAttribute('data-animation-offset');
        var elementOffsetArray = [this.verticalOffset, this.horizontalOffset];

        if (elementOffset) {
            var stringArray = elementOffset.split(',');
            if (stringArray.length === 1) {
                elementOffsetArray = [parseFloat(stringArray[0]), parseFloat(stringArray[0])];
            } else {
                elementOffsetArray = [parseFloat(stringArray[0]), parseFloat(stringArray[1])];
            }
        }

        return elementOffsetArray;
    };

    /**
     * Determine whether an element is within the viewport
     * @param  {HTMLElement}  el Element to test for
     * @return {String} Position of scroll
     * @return {Boolean}
     */
    Animate.prototype._isInView = function (el) {
        // Dimensions
        var dimensions = el.getBoundingClientRect();
        var viewportHeight = window.innerHeight || document.documentElement.clientHeight;
        var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

        // Offset
        var elementOffset = this._getElementOffset(el);
        var verticalOffset = elementOffset[0];
        var horizontalOffset = elementOffset[1];

        // Vertical
        var isInViewFromTop = dimensions.bottom - dimensions.height * verticalOffset > 0;
        var isInViewFromBottom = dimensions.top + dimensions.height * verticalOffset < viewportHeight;
        var isInViewVertically = isInViewFromTop && isInViewFromBottom;

        // Horizontal
        var isInViewFromLeft = dimensions.right - dimensions.width * horizontalOffset > 0;
        var isInViewFromRight = dimensions.left + dimensions.width * horizontalOffset < viewportWidth;
        var isInViewHorizontally = isInViewFromLeft && isInViewFromRight;

        return isInViewVertically && isInViewHorizontally;
    };

    /**
     * Tests whether a DOM node's visibility attribute is set to true
     * @private
     * @param  {HTMLElement}  el Element to test
     * @return {Boolean}
     */
    Animate.prototype._isVisible = function (el) {
        var visibility = el.getAttribute('data-visibility');
        return visibility === 'true';
    };

    /**
     * Tests whether a DOM node has already been animated
     * @private
     * @param  {HTMLElement}  el Element to test
     * @return {Boolean}
     */
    Animate.prototype._hasAnimated = function (el) {
        var animated = el.getAttribute('data-animated');
        return animated === 'true';
    };

    /**
     * Test whether an object is of a give type
     * @private
     * @param  {String}  type Type to test for e.g. 'String', 'Array'
     * @param  {Object}  obj  Object to test type against
     * @return {Boolean}      Whether object is of a type
     */
    Animate.prototype._isType = function (type, obj) {
        var test = Object.prototype.toString.call(obj).slice(8, -1);
        return obj !== null && obj !== undefined && test === type;
    };

    /**
     * Add animation to given element
     * @private
     * @param {HTMLElement} el Element to target
     */
    Animate.prototype._addAnimation = function (el) {
        if (!this._isVisible(el)) {
            this._doCallback(this.options.callbackOnInView, el);

            var classes = el.getAttribute('data-animation-classes');
            if (classes) {
                el.setAttribute('data-visibility', true);
                var animations = classes.split(' ');
                var animationDelay = parseInt(el.getAttribute('data-animation-delay'), 10) || this.options.delay;
                var addAnimation = function addAnimation(animation) {
                    el.classList.add(animation);
                };

                if (animationDelay && this._isType('Number', animationDelay) && animationDelay !== 0) {
                    setTimeout(function () {
                        animations.forEach(addAnimation);
                    }, animationDelay);
                } else {
                    animations.forEach(addAnimation);
                }

                this._completeAnimation(el);
            } else {
                console.error('No animation classes were given');
            }
        }
    };

    /**
     * Remove animation from given element
     * @private
     * @param {HTMLElement} el Element to target
     */
    Animate.prototype._removeAnimation = function (el) {
        var classes = el.getAttribute('data-animation-classes');
        if (classes) {
            el.setAttribute('data-visibility', false);
            el.removeAttribute('data-animated');
            var animations = classes.split(' ');
            var animationDelay = parseInt(el.getAttribute('data-animation-delay'), 10);
            var removeAnimation = function removeAnimation(animation) {
                el.classList.remove(animation);
            };

            animations.push(this.options.animatedClass);

            if (animationDelay && this._isType('Number', animationDelay)) {
                setTimeout(function () {
                    animations.forEach(removeAnimation);
                }, animationDelay);
            } else {
                animations.forEach(removeAnimation);
            }
        } else {
            console.error('No animation classes were given');
        }
    };

    /**
     * If valid callback has been passed, run it with optional element as a parameter
     * @private
     * @param  {Function}         fn Callback function
     */
    Animate.prototype._doCallback = function (fn) {
        var el = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        if (fn && this._isType('Function', fn)) {
            fn(el);
        } else {
            console.error('Callback is not a function');
        }
    };

    /**
     * Add class & data attribute to element on animation completion
     * @private
     * @param  {HTMLElement} el Element to target
     */
    Animate.prototype._completeAnimation = function (el) {
        // Store animation event
        var animationEvent = this._whichAnimationEvent();

        // When animation event has finished
        el.addEventListener(animationEvent, function () {
            var removeOverride = el.getAttribute('data-animation-remove');

            // If remove animations on completion option is turned on
            if (removeOverride !== 'false' && this.options.remove) {
                // Separate each class held in the animation classes attribute
                var animations = el.getAttribute('data-animation-classes').split(' ');
                var removeAnimation = function removeAnimation(animation) {
                    el.classList.remove(animation);
                };

                // Remove each animation from element
                animations.forEach(removeAnimation);
            }

            // Add animation complete class
            el.classList.add(this.options.animatedClass);
            // Set animated attribute to true
            el.setAttribute('data-animated', true);

            this._doCallback(this.options.callbackOnAnimate, el);
        }.bind(this));
    };

    /**
     * Remove event listeners
     * @public
     */
    Animate.prototype.removeEventListeners = function () {
        if (this.options.onResize) {
            window.removeEventListener('resize', this.throttledEvent, false);
        }

        if (this.options.onScroll) {
            window.removeEventListener('scroll', this.throttledEvent, false);
        }
    };

    /**
     * Add event listeners
     * @public
     */
    Animate.prototype.addEventListeners = function () {
        if (this.options.onLoad) {
            document.addEventListener('DOMContentLoaded', function () {
                this.render(true);
            }.bind(this));
        }

        if (this.options.onResize) {
            window.addEventListener('resize', this.throttledEvent, false);
        }

        if (this.options.onScroll) {
            window.addEventListener('scroll', this.throttledEvent, false);
        }
    };

    /**
     * Initializes Animate.js and adds event listeners
     * @public
     */
    Animate.prototype.init = function () {
        // If browser doesn't cut the mustard, let it fail silently
        if (!this.supports) return;

        this.initialised = true;

        this.addEventListeners();

        this._doCallback(this.options.callbackOnInit);
    };

    /**
     * Stop all running event listeners & resets options to null
     * @public
     */
    Animate.prototype.kill = function () {
        // If we haven't initialised, there is nothing to kill.
        if (!this.initialised) return;

        this.removeEventListeners();

        // Reset settings
        this.options = null;
        this.initialised = false;
    };

    /**
     * Toggles animations on an event
     * @public
     * @return {}
     */
    Animate.prototype.render = function (onLoad) {
        if (this.initialised) {
            // If a disability filter function has been passed...
            if (this.options.disableFilter && this._isType('Function', this.options.disableFilter)) {
                var test = this.options.disableFilter();
                // ...and it passes, kill render
                if (test === true) return;
            }

            // Grab all elements in the DOM with the correct target
            var els = this.elements;

            // Loop through all elements
            for (var i = els.length - 1; i >= 0; i--) {
                // Store element at location 'i'
                var el = els[i];

                // If element is in view
                if (this._isInView(el)) {
                    // Add those snazzy animations
                    this._addAnimation(el);
                } else if (this._hasAnimated(el)) {
                    // See whether it has a reverse override
                    var reverseOverride = el.getAttribute('data-animation-reverse');

                    if (reverseOverride !== 'false' && this.options.reverse) {
                        this._removeAnimation(el);
                    }
                } else if (onLoad) {
                    var animateScrolled = el.getAttribute('data-animation-scrolled');

                    // If this render has been triggered on load and the element is above our current
                    // scroll position and the `scrolled` option is set, animate it.
                    if ((this.options.scrolled || animateScrolled) && this._isAboveScrollPos(el)) {
                        this._addAnimation(el);
                    }
                }
            }
        }
    };

    return Animate;
}();

exports.Animate = Animate;

},{}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var __assign = undefined && undefined.__assign || function () {
  return (__assign = Object.assign || function (t) {
    for (var i, a = 1, s = arguments.length; a < s; a++) {
      for (var n in i = arguments[a]) {
        Object.prototype.hasOwnProperty.call(i, n) && (t[n] = i[n]);
      }
    }return t;
  }).apply(this, arguments);
},
    CountUp = function () {
  function t(t, i, a) {
    var s = this;this.target = t, this.endVal = i, this.options = a, this.version = "2.0.4", this.defaults = { startVal: 0, decimalPlaces: 0, duration: 2, useEasing: !0, useGrouping: !0, smartEasingThreshold: 999, smartEasingAmount: 333, separator: ",", decimal: ".", prefix: "", suffix: "" }, this.finalEndVal = null, this.useEasing = !0, this.countDown = !1, this.error = "", this.startVal = 0, this.paused = !0, this.count = function (t) {
      s.startTime || (s.startTime = t);var i = t - s.startTime;s.remaining = s.duration - i, s.useEasing ? s.countDown ? s.frameVal = s.startVal - s.easingFn(i, 0, s.startVal - s.endVal, s.duration) : s.frameVal = s.easingFn(i, s.startVal, s.endVal - s.startVal, s.duration) : s.countDown ? s.frameVal = s.startVal - (s.startVal - s.endVal) * (i / s.duration) : s.frameVal = s.startVal + (s.endVal - s.startVal) * (i / s.duration), s.countDown ? s.frameVal = s.frameVal < s.endVal ? s.endVal : s.frameVal : s.frameVal = s.frameVal > s.endVal ? s.endVal : s.frameVal, s.frameVal = Math.round(s.frameVal * s.decimalMult) / s.decimalMult, s.printValue(s.frameVal), i < s.duration ? s.rAF = requestAnimationFrame(s.count) : null !== s.finalEndVal ? s.update(s.finalEndVal) : s.callback && s.callback();
    }, this.formatNumber = function (t) {
      var i,
          a,
          n,
          e,
          r,
          o = t < 0 ? "-" : "";if (i = Math.abs(t).toFixed(s.options.decimalPlaces), n = (a = (i += "").split("."))[0], e = a.length > 1 ? s.options.decimal + a[1] : "", s.options.useGrouping) {
        r = "";for (var l = 0, h = n.length; l < h; ++l) {
          0 !== l && l % 3 == 0 && (r = s.options.separator + r), r = n[h - l - 1] + r;
        }n = r;
      }return s.options.numerals && s.options.numerals.length && (n = n.replace(/[0-9]/g, function (t) {
        return s.options.numerals[+t];
      }), e = e.replace(/[0-9]/g, function (t) {
        return s.options.numerals[+t];
      })), o + s.options.prefix + n + e + s.options.suffix;
    }, this.easeOutExpo = function (t, i, a, s) {
      return a * (1 - Math.pow(2, -10 * t / s)) * 1024 / 1023 + i;
    }, this.options = __assign({}, this.defaults, a), this.formattingFn = this.options.formattingFn ? this.options.formattingFn : this.formatNumber, this.easingFn = this.options.easingFn ? this.options.easingFn : this.easeOutExpo, this.startVal = this.validateValue(this.options.startVal), this.frameVal = this.startVal, this.endVal = this.validateValue(i), this.options.decimalPlaces = Math.max(this.options.decimalPlaces), this.decimalMult = Math.pow(10, this.options.decimalPlaces), this.resetDuration(), this.options.separator = String(this.options.separator), this.useEasing = this.options.useEasing, "" === this.options.separator && (this.options.useGrouping = !1), this.el = "string" == typeof t ? document.getElementById(t) : t, this.el ? this.printValue(this.startVal) : this.error = "[CountUp] target is null or undefined";
  }return t.prototype.determineDirectionAndSmartEasing = function () {
    var t = this.finalEndVal ? this.finalEndVal : this.endVal;this.countDown = this.startVal > t;var i = t - this.startVal;if (Math.abs(i) > this.options.smartEasingThreshold) {
      this.finalEndVal = t;var a = this.countDown ? 1 : -1;this.endVal = t + a * this.options.smartEasingAmount, this.duration = this.duration / 2;
    } else this.endVal = t, this.finalEndVal = null;this.finalEndVal ? this.useEasing = !1 : this.useEasing = this.options.useEasing;
  }, t.prototype.start = function (t) {
    this.error || (this.callback = t, this.duration > 0 ? (this.determineDirectionAndSmartEasing(), this.paused = !1, this.rAF = requestAnimationFrame(this.count)) : this.printValue(this.endVal));
  }, t.prototype.pauseResume = function () {
    this.paused ? (this.startTime = null, this.duration = this.remaining, this.startVal = this.frameVal, this.determineDirectionAndSmartEasing(), this.rAF = requestAnimationFrame(this.count)) : cancelAnimationFrame(this.rAF), this.paused = !this.paused;
  }, t.prototype.reset = function () {
    cancelAnimationFrame(this.rAF), this.paused = !0, this.resetDuration(), this.startVal = this.validateValue(this.options.startVal), this.frameVal = this.startVal, this.printValue(this.startVal);
  }, t.prototype.update = function (t) {
    cancelAnimationFrame(this.rAF), this.startTime = null, this.endVal = this.validateValue(t), this.endVal !== this.frameVal && (this.startVal = this.frameVal, this.finalEndVal || this.resetDuration(), this.determineDirectionAndSmartEasing(), this.rAF = requestAnimationFrame(this.count));
  }, t.prototype.printValue = function (t) {
    var i = this.formattingFn(t);"INPUT" === this.el.tagName ? this.el.value = i : "text" === this.el.tagName || "tspan" === this.el.tagName ? this.el.textContent = i : this.el.innerHTML = i;
  }, t.prototype.ensureNumber = function (t) {
    return "number" == typeof t && !isNaN(t);
  }, t.prototype.validateValue = function (t) {
    var i = Number(t);return this.ensureNumber(i) ? i : (this.error = "[CountUp] invalid start or end value: " + t, null);
  }, t.prototype.resetDuration = function () {
    this.startTime = null, this.duration = 1e3 * Number(this.options.duration), this.remaining = this.duration;
  }, t;
}();exports.CountUp = CountUp;

},{}],3:[function(require,module,exports){
'use strict';

var _Animate = require('./libs/Animate.js');

var _CountUpMin = require('./libs/CountUp.min.js');

var App = function () {

  // Variables

  // Event listener
  var addEventListeners = function addEventListeners() {

    // Fixed nav
    var nav = document.querySelector('.header');
    var topOfNav = nav.offsetTop;
    function fixNav() {
      if (window.scrollY >= topOfNav) {
        nav.classList.add('fixed-nav');
      } else {
        nav.classList.remove('fixed-nav');
      }
    }
    window.addEventListener('scroll', fixNav);
    fixNav();

    // Hamburger
    if (document.querySelector('.nav-switcher')) {
      document.querySelectorAll('.nav-switcher').forEach(function (navSwitcher) {
        navSwitcher.addEventListener('click', function () {
          document.querySelector('body').classList.toggle('menu-open');
        });
      });
    }

    // Map
    if (document.querySelectorAll('[data-tooltip]')) {
      document.querySelectorAll('[data-tooltip]').forEach(function (country) {
        country.addEventListener('click', function (e) {
          var element,
              tooltip = document.querySelector('.map .tooltip');

          if (e.target.getAttribute('data-tooltip')) {
            element = e.target;
          } else {
            element = e.target.closest('[data-tooltip]');
          }
          if (element.classList.contains('active')) {
            element.classList.remove('active');
            tooltip.classList.remove('active');
          } else {
            if (document.querySelector('[data-tooltip].active')) {
              document.querySelector('[data-tooltip].active').classList.remove('active');
              tooltip.classList.remove('active');
            }
            element.classList.add('active');
            tooltip.classList.add('active');
          }
          tooltip.innerHTML = element.getAttribute('data-tooltip');
          tooltip.style.left = e.layerX + 'px';
          tooltip.style.top = e.layerY + 'px';
        });
      });
      document.addEventListener('click', function (event) {
        if (!event.target.closest('[data-tooltip]')) {
          if (document.querySelector('[data-tooltip].active') && document.querySelector('.tooltip.active')) {
            document.querySelector('[data-tooltip].active').classList.remove('active');
            document.querySelector('.tooltip.active').classList.remove('active');
          }
        }
      }, false);
    }

    // Modal
    if (document.querySelectorAll('[data-modal]')) {
      document.querySelectorAll('[data-modal]').forEach(function (modal) {
        modal.addEventListener('click', function (e) {
          e.preventDefault();
          var element = void 0;

          if (e.target.getAttribute('data-tooltip')) {
            element = e.target.getAttribute('data-modal');
          } else {
            element = e.target.closest('[data-modal]').getAttribute('data-modal');
          }

          document.querySelector('html').classList.add('active');
          document.querySelector(element).classList.add('active');
        });
      });
    }

    if (document.querySelectorAll('.close-modal')) {
      document.querySelectorAll('.close-modal').forEach(function (close) {
        close.addEventListener('click', function (e) {
          var modal = e.target.closest('.modal');

          if (modal.querySelector('iframe')) {
            var video = modal.querySelector('iframe'),
                videoSrc = video.getAttribute('src');
            video.setAttribute('src', videoSrc);
          }

          document.querySelector('html').classList.remove('active');
          modal.classList.remove('active');
        });
      });
    }
  };

  var counterJs = function counterJs(element) {
    var show = element.querySelectorAll('.js-count');

    show.forEach(function (item) {
      var countUp = new _CountUpMin.CountUp(item, Number(item.getAttribute('data-value')), {
        duration: 5
      });
      if (!countUp.error) {
        countUp.start();
      }
    });
  };

  // Animations
  var animate = new _Animate.Animate({
    target: '[data-animate]',
    animatedClass: 'visible',
    offset: [0.1, 0.1],
    delay: 0,
    remove: false,
    reverse: false,
    scrolled: false,
    debug: true,
    onLoad: true,
    onScroll: true,
    onResize: false,
    callbackOnInView: function callbackOnInView(element) {
      var animationType = element.getAttribute('data-animation-classes').replace('animated', '').trim();
      if (animationType == 'counter') {
        setTimeout(function () {
          counterJs(element);
        }, 1000);
      }
    }
  });

  return {
    // Public
    init: function init() {
      // Add event listener
      addEventListeners();
      // Animations
      animate.init();
    }
  };
}();

// Init
App.init();

},{"./libs/Animate.js":1,"./libs/CountUp.min.js":2}]},{},[3]);
